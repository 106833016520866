.errorBorder{
    border: 1px solid #DF000C !important;
}

.input-transparent:not(:nth-child(0)) {
    opacity: 1 !important;
}

.menu{
    // background: linear-gradient(180deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0.065) 100%);
    backdrop-filter: blur(6px);
    /* Note: backdrop-filter has minimal browser support */
    color: white;
    // background-color: red;
    border-radius: 14px;
}
.singleValue{
    color:white;
}


.auth-form{
    padding: 40px;
    
    @media (max-width:767px){
        padding: 25px;
    }
    @media (max-width:300px){
        padding: 20px;
    }
}
.padButton{
    padding: 0.8rem;
}
.backLogin{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #DF000C;
}
// .requestCodeBtn{
//     background: #000063;
//     box-shadow: 0px 0px 10px rgba(1, 32, 96, 0.35);
//     border-radius: 107px;
//     // &:hover, &:active, &:focus{
//     //     background: linear-gradient(90deg, #000063 -0.1%, #1F9C7C 99.9%);
//     // }
// }
// .requestCodeBtnText{
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 24px;
//     /* identical to box height */
//     text-transform: uppercase;
//     color: #FFFFFF;
// }

.input-container{
    opacity: 0.4;
    border: 1px solid #FFFFFF;
    border-radius: 116px;
    background-color: transparent;
    color: #ffffff !important;
}

.input-container:hover, .input-container:focus{
    background-color: transparent;
    border: 1px solid #1B2626;
}



.date-picker-container .MuiInput-underline:before, .date-picker-container .MuiInput-underline:after{
    content: '' !important;
    border-bottom: none !important;
}
.date-picker-container .MuiFormControl-root{
    background: transparent;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table-responsive::-webkit-scrollbar {
    // display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.table-responsive {
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
}

.dataTables_wrapper .dataTables_paginate > .paginate_button{
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50%!important;
    padding: 0;
    font-size: 1rem;
}

a .card-title:hover{
    color: var(--primary);
}


input:disabled {
    // background-color: #f2f2f2 !important;
}

.form-input-check{
    background-color: #fd7e14 !important;
}

a{
    cursor: pointer;
}

.lang-flag{
    width: 30px;
    height: auto;
}
.error{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FF6666;
}

.input{
    background: transparent;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: black !important;
    &:focus, &:hover{
        background: transparent;
    }
}

.text-area{
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    background: transparent;
    color: #012060 !important;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    min-height: 100px !important;
    &:focus, &:hover{
        background: transparent;
    }
}

::placeholder{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: rgba(54,56,72, 0.6) !important;
    @media screen and (max-width:767px) {
        font-size: 11px;
    }
}

.pencil-icon{
    width:70% !important;

    @media screen and (max-width:767px) {
        width:50% !important;
    }
}

.input-border{
    border: 1px solid #363848;
    border-radius: 25px;
    // opacity: 0.4;
    &.disabled{
        background:#e0e0e0;
    }
}

.bg-transparent{
    background: transparent;
}

.primary-btn{
    cursor: pointer;
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    box-shadow: 0px 0px 37px rgba(1, 32, 96, 0.35);
    border-radius: 107px;
    transition: all 1s ease;
    color: #ffffff;
    &:hover{
        background: linear-gradient(186.87deg, #000063 1.86%, #AF0C15 94.58%);
    }
}

.react-calendar__month-view__weekdays__weekday{
    color: #000000;
}



.dialCode {
    border-radius: 0px;
    background: white;
    border-right: 2px solid #d7dae3;
    font-size: 16px;
    color: #111111 !important;

    @media screen and (max-width:767px) {
        font-size: 12px;
    }
}

.auth-input-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;
}

.auth-btn{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    box-shadow: 0px 0px 37px rgba(1, 32, 96, 0.35);
    border-radius: 107px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    transition: all 1s;
    &:hover{
        background: linear-gradient(90deg, #000063 -0.1%, #AF0C15 99.9%);
    }
}

.content-body{
    background: #ffffff;
}

.authenticated-bg{
    background: transparent;
}

.header{
    background: transparent;
}

.header-content{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    border-radius: 0px 0px 30px 0px;
    // @media only screen and (max-width: 47.9375rem){
    //     border-radius: 0px;
    // }
}

.text-primary{
    color: #012060 !important;
}

.cardBg{
    background: #FFFFFF;
    box-shadow: 0px 3px 8px #C1BDBD;
    backdrop-filter: blur(21px);
    border-radius: 16px;
}

.card-small-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-transform: uppercase;
    display: block;
    @media screen and (max-width:767px){
        font-size: 14px;
    }
}

.backInvestment{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    /* #363848 */
    color: #363848;
    border-bottom: 1px solid #363848;
    cursor: pointer;
}

.card-icon{
    width: 90px;
}

.normal-number{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #012060;
}

.big-number{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 48px;
    text-transform: uppercase;
    color: #012060;

    @media screen and (max-width:767px) {
        font-size: 20px;
    }
}

.small-desc{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #012060;
}

.xsmall-desc{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    color: #012060;
}

.w-70{
    width: 70%;
}

.trend-up{
    background: linear-gradient(78.66deg, rgba(33, 107, 113, 0) 2.93%, rgba(33, 107, 113, 0.7) 92.72%);
    border-radius: 51px;
}

.percentage-trend-up{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    text-align: right;
    text-transform: uppercase;
    color: #25C6C6;
}

.trend-down{
    background: linear-gradient(78.66deg, rgba(254, 101, 101, 0) 2.93%, rgba(254, 101, 101, 0.35) 92.72%);
    border-radius: 51px;
}

.percentage-trend-down{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    text-align: right;
    text-transform: uppercase;
    color: #FE6565;
}

.status{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 96.3%;
    text-transform: uppercase;
    &.ACTIVE{
        color: #00B3FF;
    }
    &.PENDING{
        color: #7000FF;
    }
    &.EXPIRED{
        color: #A0A9A9;
    }
    &.CANCELLED{
        color: #FF6666;
    }
    &.COMPLETED{
        color: #25C6C6;
    }
    &.FAILED{
        color: #FF6666;
    }
}

td{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 96.3%;
    text-transform: uppercase;
    color: #181818;
}

thead th{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 96.3%;
    text-align: center;
    text-transform: uppercase !important;
    color: #000063;
    text-align: center;

}

.table>thead>tr>th {
    border-color: #B8C0C0 !important;
    
}
.table tbody tr td{
    border-color: #B8C0C0;
}

.currency{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #012060;
}

.invest-currency{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: 0.05em;
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;
}

//investment
.god-name{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    color: #012060;
}

.investment-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;

    @media screen and (max-width:767px) {
        font-size:13px;
    }
        
   
}

.investment-value{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-align: right;
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
    word-wrap: break-word;

    @media screen and (max-width:767px) {
        font-size:13px;
    }
}

.god-price-container{
    background: linear-gradient(180deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0.065) 100%);
    border-radius: 14px;
}

.god-price{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
}
.investment-productToTrade{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */
    text-align: center;
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;

}
.investment-productName{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 52px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    /* Linear */
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.colorBg{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
}
.lockdown-period{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.god-minRoipercent{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    
}

.god-minRoi{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #012060;
    opacity: 0.7;
}

.god-remark{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #012060;
}

.choose-package-btn{
    cursor: pointer;
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    // box-shadow: 0px 0px 37px;
    border-radius: 107px;
    &:hover{
        background: linear-gradient(86.87deg, #000063 1.86%, #AF0C15 94.58%);
    }
}


.confirmPassword-btn{
    cursor: pointer;
    background: linear-gradient(86.87deg, #116466 1.86%, #25C6C6 94.58%);
    box-shadow: 0px 4px 15px rgba(37, 198, 198, 0.35);
    border-radius: 107px;
    &:hover{
        background: linear-gradient(86.87deg, #25C6C6 1.86%, #116466 94.58%);
    }
}


.css-tlfecz-indicatorContainer{
    color: #000063 !important;
}
.primary-btn-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
}

.choose-package-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}

.small-trial-price{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #DDB590;
    text-decoration: underline;
}
.divider{
    width: 2px;
    height: 5vh;
    border: 1px solid rgba(235, 235, 235, 0.5);
}

.divide{
    width: 2px;
    height: 5vh;
    border: 1px solid rgba(235, 235, 235, 0.5);
}

.bgprofileSetting{
    background: linear-gradient(152.97deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(21px);
    padding:15px;
    min-width: 13.5vw;
}
.dropdown-item:hover, .dropdown-item:focus{
    background-color: transparent !important;
    color:#969ba0 !important;
}



.investment-container{
    &.active{
        background: radial-gradient(74.54% 81.26% at 109.76% -57.08%, rgba(255, 0, 0, 0.365) 0%, rgba(255, 124, 124, 0.065) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(217.91deg, #FFFFFF 34.89%, #CAD9FF 109.1%);
        box-shadow: 0px 7px 15px #9EB9FD;
        border-radius: 16px;
    }
    &:hover{
        background: radial-gradient(74.54% 81.26% at 109.76% -57.08%, rgba(255, 0, 0, 0.365) 0%, rgba(255, 124, 124, 0.065) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(217.91deg, #FFFFFF 34.89%, #CAD9FF 109.1%);
        box-shadow: 0px 7px 15px #9EB9FD;
        border-radius: 16px;
    }
}

.investModal{
    .modal-content{
        background: linear-gradient(#ffffff , #ffffff ) padding-box,
        linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
        border: 1px solid transparent;
        backdrop-filter: blur(11.5px);
        border-radius: 16px;
    }
    .modal-header, .modal-footer{
        border: none;
        width: 100%;
        display: block;
    }
    .modal-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        /* identical to box height */
        text-align: center;
        text-transform: capitalize;
        /* Linear */
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media screen and (max-width:767px) {
            font-size:24px;
        }
    }
    .btn-close{
        background: url('../images/common/close.png') center/1em auto no-repeat;
     
    }
    .invest-amount-select{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #012060;
    }
    .invest-amount-select-container{
        background: linear-gradient(#ffffff , #ffffff ) padding-box,
        linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
        border: 1px solid transparent;
        filter: drop-shadow(0px 2px 8px rgba(175, 12, 21, 0.4));
        border-radius: 16px;
        text-align: center;
        cursor: pointer;
        &:hover, &.active{
            background: #000063;
            box-shadow: 0px 2px 8px rgba(175, 12, 21, 0.4);
            .invest-amount-select{
                color: #EBEBEB;
            }
        }
    }
}

.packageModal{
    .modal-dialog {
        max-width: 30%;
        @media only screen and (min-width: 992px) and (max-width: 1400px) {
            max-width: 38%;
    
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            max-width: 70%;
    
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
    .modal-content{
        background: linear-gradient(#ffffff , #ffffff ) padding-box,
        linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
        border: 1px solid transparent;
        backdrop-filter: blur(11.5px);
        border-radius: 16px;
    }
    .modal-header, .modal-footer{
        border: none;
        width: 100%;
        display: block;
    }
    .modal-title{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;
        color: #012060;

       
    }
    .btn-close{
        background: transparent url('../images/common/close.png') center/1em auto no-repeat;
    }
    .crypto-container{
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(21px);
        border-radius: 16px;
        cursor: pointer;
        &:hover, &.active{
            background: linear-gradient(78.66deg, rgba(37, 198, 198, 0) 2.93%, rgba(37, 198, 198, 0.6) 92.72%);
            filter: drop-shadow(0px 4px 17px rgba(27, 148, 149, 0.54));
        }
    }
    .crypto-number{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 89.8%;
        text-transform: uppercase;
        color: #012060;
    }
    .currency{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 12px;
        text-transform: uppercase;
        color: #012060;
    }
    .god-img{
        margin-top: -15%;
    }
}


.secondaryPasswordModal{
    .modal-content{
        background: linear-gradient(#ffffff , #ffffff ) padding-box,
        linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
        border: 1px solid transparent;
        
        border-radius: 16px;
    }
    .modal-header, .modal-footer{
        border: none;
        width: 100%;
        display: block;
    }
    .secPassword-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        /* identical to box height */
        text-align: center;
        text-transform: capitalize;
        /* Linear */
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media screen and (max-width:767px) {
            font-size: 24px;
        }
    }
    .modal-title{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;
        color: #012060;
    }
    .btn-close{
        background: transparent url('../images/common/close.png') center/1em auto no-repeat;
    }
    .invest-amount-select{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #012060;
    }
    .invest-amount-select-container{
        border: 1px solid #D9D9D9;
        border-radius: 58px;
        text-align: center;
        cursor: pointer;
        &:hover, &.active{
            background: linear-gradient(180deg, #012060 0%, rgba(235, 235, 235, 0.65) 100%);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
            .invest-amount-select{
                color: #303030;
            }
        }
    }
}

.unsubModal{
    .modal-dialog {
        max-width: 25%;

        @media only screen and (min-width: 768px) and (max-width: 991px) {
            max-width: 70%;
    
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
    .modal-content{
        background: linear-gradient(#ffffff , #ffffff ) padding-box,
        linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
        border: 1px solid transparent;
        backdrop-filter: blur(11.5px);
        border-radius: 16px;
    }
    .modal-header, .modal-footer{
        border: none;
        width: 100%;
        display: block;
    }
    .secPassword-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        /* identical to box height */
        text-align: center;
        text-transform: capitalize;
        /* Linear */
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media screen and (max-width:767px) {
            font-size: 24px;
        }
    }
    .modal-title{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;
        color: #012060;
    }
    .btn-close{
        background: transparent url('../images/common/close.png') center/1em auto no-repeat;
    }
    .invest-amount-select{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #012060;
    }
    .invest-amount-select-container{
        border: 1px solid #D9D9D9;
        border-radius: 58px;
        text-align: center;
        cursor: pointer;
        &:hover, &.active{
            background: linear-gradient(180deg, #012060 0%, rgba(235, 235, 235, 0.65) 100%);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
            .invest-amount-select{
                color: #303030;
            }
        }
    }
}


.text-right{
    text-align: right;
}



.css-1vs3jbz:first-of-type::after{
    border-radius:0 !important;
}
.css-1vs3jbz:last-of-type::before{
    border-radius:0 !important;
}

.css-1vs3jbz::before, .css-1vs3jbz::after {
    height: 34px !important;

}
.test{
    margin-right: 6rem
}
.StyledNode{
    margin-top: 2rem;
}
.Node-child-label{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #DDB590;
}
.css-4h8rul::before{
    height: 0px !important;
}
.back-home-btn{
    background: linear-gradient(#ffffff , #ffffff ) padding-box,
    linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
    border: 1px solid transparent;
    // filter: drop-shadow(0px 0px 37px rgba(1, 32, 96, 0.35));
    border-radius: 107px;
}
.back-home-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    /* 000063 */   
    color: #000063;
    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}
.transferModel, .successModel, .failModel, .passwordModal .unsubModal{
    .modal-dialog {
        max-width: 28%;
        @media only screen and (min-width: 992px) and (max-width: 1400px) {
            max-width: 35%;
    
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            max-width: 70%;
    
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
    .modal-content{
        background: linear-gradient(#ffffff , #ffffff ) padding-box,
        linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
        border: 1px solid transparent;
        backdrop-filter: blur(11.5px);
        border-radius: 16px;
    }
    .gasfee-failsubscript-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        /* identical to box height */

        text-align: center;
        text-transform: capitalize;

        /* Linear */

        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media screen and (max-width:767px) {
            font-size: 24px;
        }
    }
    .modal-header, .modal-footer{
        border: none;
        width: 100%;
        display: block;
    }
    .transfer-img{
        width: 60%;
    }
    .transferring-text{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #012060;
    }
    .btn-close{
        background: transparent url('../images/common/close.png') center/1em auto no-repeat;
    }
    .success-subscript-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */
        
        text-align: center;
        text-transform: capitalize;
        
        /* Linear */
        
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .withdraw-subscript-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 100%;
        /* or 30px */
        text-align: center;
        text-transform: capitalize;
        /* Linear */
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media screen and (max-width:767px) {
            font-size: 20px;
        }
    }
    .fail-subscript-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
        text-transform: capitalize;
        /* #D9D9D9 */
        color: #DF000C;
    }
    .warning-label{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 96.3%;
        /* or 15px */

        text-transform: capitalize;

        /* #D9D9D9 */

        color: #DF000C;
    }

    .warning-value{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 96.3%;
        /* or 15px */
        text-transform: capitalize;
        /* #D9D9D9 */
        color: #DF000C;
    }
    .payment-success-img{
        width: 30%;
    }
    .payment-success-container{
        margin-top: -40%;

        @media screen and (max-width:767px) {
            margin-top: -15%;
        }
    }

    .dividerNotEnough{
        border-bottom: 1px solid #CCCCCC;
    }
   
}





.profileModal{
    .modal-content{
        background: linear-gradient(152.97deg, #FFFFFF 0%, #FFFFFF 100%);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(21px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 16px;
    }
    .modal-header, .modal-footer{
        border: none;
        width: 100%;
        display: block;
    }
    .transfer-img{
        width: 60%;
    }
    .transferring-text{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #012060;
    }
    .btn-close{
        background: transparent url('../images/common/close.png') center/1em auto no-repeat;
    }
    .success-subscript-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        /* or 20px */
        
        text-align: center;
        text-transform: capitalize;
        
        /* Linear */
        
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .fail-subscript-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
        text-transform: capitalize;
        /* #D9D9D9 */
        color: #DF000C;
    }
    .payment-success-img{
        width: 30%;
    }
    .payment-success-container{
        margin-top: -25%;
    }
   
}

.investHistory{
 
    td{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    td:not(:last-child){
        border-right: 1px solid rgba(235, 235, 235, 0.2);
        border-left: 1px solid rgba(235, 235, 235, 0.2);
    }
    td:last-child{
        border-right: 1px solid rgba(235, 235, 235, 0.2);
    }
    .investHistory-th{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 96.3%;
        /* or 15px */
        /* #D9D9D9 */
        color: #FFFFFF;
        background: #000063;
    }
    .investHistory-txt{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 96.3%;
        /* identical to box height, or 13px */
        text-transform: uppercase;
        /* 000063 */
        color: #000063;
        background: rgba(248, 248, 248, 0.65);
        @media screen and (max-width:767px) {
            font-size: 13px;
        }
    }
}

.close-form-btn{
    border: 1px solid rgba(235, 235, 235, 0.8);
    filter: drop-shadow(0px 4px 37px rgba(37, 198, 198, 0.35));
    border-radius: 107px;
    cursor: pointer;
    background: transparent;
    &:hover{
        background: linear-gradient(86.87deg, rgba(255, 255, 255, 0.3) 1.86%, rgba(255, 255, 255, 0.3) 94.58%);
    }
}

.close-form-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.card-dashboard-content{
    min-height: 35vh;
}


.card-mobile{
    display:none;
}

#table-scroll {
    overflow-x: scroll;  
}

.ai-icon{
    padding-left: 0px !important;
}

.metismenu > li{
    border-left: 5px solid rgba(255,255,255,0);
} 

.mm-active {
    color: #DF000C !important;
}

.selected-lang-bg{
    background: linear-gradient(78.66deg, rgba(37, 198, 198, 0.2) 31.46%, rgba(37, 198, 198, 0) 92.72%);
}

a:hover {
    color: #DF000C;
}



.dropDownProfile {
    width: 50px;
    border-radius: 50%;
    aspect-ratio: 1;

    @media screen and (max-width:767px) {
        width: 75px;
    }
}
.profileName{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #012060;
    word-wrap: break-word;
}
.profileID{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #A9A9A9;
    word-wrap: break-word;
}
.link-label{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #363848;
}
.referral-label{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #363848;
}
.dropdown-borderbox{
    background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0.65) 100%);
    border: 1px solid #CCCCCC;
    border-radius: 7px;
}

.dropdown-referral-code{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #363848;
}

.dropdown-referral-link{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #363848;
    word-break: break-word;
  
}

.pips-icon{
    width: 40%;
}

.withdrawal-btn{
    cursor: pointer;
    border-radius:107px;
    border:1px solid #CD9564;
    left: 0;
    right: 0;
    margin: auto;
    &:hover{
        background:rgba(205, 149, 100, 0.3);
    }
}


.withdrawal-btn-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    background: linear-gradient(86.87deg, #CD9564 1.86%, #FFCB9A 94.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}



.unsubsribe-btn{
    cursor: pointer;
    border-radius:107px;
    background:rgba(235, 235, 235, 0.3);
    &:hover{
        background:grey;
    }
    @media screen and (max-width:767px) {
        border-radius: 0px;
        background: transparent !important;
        box-shadow: none !important;
    }
}

.unsubsribe-btn-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */
    text-align: center;
    color: #012060;
    @media screen and (max-width:767px) {
        color: #787878;
        text-decoration-line: underline;
    }
}

.withdraw-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    /* identical to box height */
    text-align: center;
    /* Linear */
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media screen and (max-width:767px) {
        font-size:24px;
    }
}

.withdraw-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    /* #D9D9D9 */
    color: #181818;

    @media screen and (max-width:767px) {
        font-size:13px;
    }
}

.withdraw-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-align: right;
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
    @media screen and (max-width:767px) {
        font-size:13px;
    }
}

.withdrawal-amount-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;

    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}

.unsub-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    /* Linear */
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media screen and (max-width:767px) {
        font-size: 24px;
    }

}

.unsub-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
   
    /* #D9D9D9 */
    color: #181818;

    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}


.unsub-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-align: right;
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}
  
//   .pagination__link--disabled a {
//     color: rgb(198, 197, 202);
//     border: 1px solid rgb(198, 197, 202);
//   }
.investHistory-desktop{
    display:block;
}
.investHistory-mobile{
    display:none;
}
.investmentDetailHeader-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */
    color: #000000;
}

.investmentDetailHeader-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
    &.ACTIVE{
        color: #29ABE2;
    }
    &.PENDING{
        color: #DDB590;
    }
    &.EXPIRED{
        color: #A0A9A9;
    }
    &.CANCELLED{
        color: #FF6666;
    }
    &.COMPLETED{
        color: #25C6C6;
    }
    &.FAILED{
        color: #DF000C;;
    }
}

.investmentDetailBody-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */
    
    text-transform: capitalize;
    
    /* #D9D9D9 */
    
    color: #181818;
}

.investmentDetailBody-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */

    text-transform: capitalize;
    /* 000063 */
    color: #000063;

}

.table_header{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 96.3%;
    /* identical to box height, or 11px */
    text-align: center;
    text-transform: uppercase;
    color: rgba(235, 235, 235, 0.6);
}
.table_content{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 96.3%;
    /* identical to box height, or 12px */
    text-align: center;
    color: #012060;
}

.accordion-header.collapsed .accordion-header-indicator::before {
    display: none;
}

.cardBgMobile{
    background: #F8F8F8;
    /* #CCCCCC */
    border: 1px solid #CCCCCC;
    border-radius: 10px;
}

.accordion-header:not(.collapsed) .accordion-header-indicator::before {
    display: none;
}

.success-content{
    background: linear-gradient(180deg, rgba(249, 249, 249, 0.15) 0%, rgba(249, 249, 249, 0.0975) 100%);
    box-shadow: 0px 4px 31px rgba(24, 55, 51, 0.4);
    backdrop-filter: blur(15px);
    border-radius: 30px; 
    text-align:center;
}
.thankYou-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.back-label{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #363848;
    text-decoration: underline;
}
@media only screen and (min-width: 668px) and (max-width: 992px) {
    .w-70{
        width: 100%;
    }
    .card-icon{
        width: 30%;
    }
    // .cardBg{
    //     min-height: 25vh;
    // }
  
    .card-desktop{
        display:none;
    }
    .card-mobile{
        display:block;
    }
    .card-dashboard-content{
        min-height: 18vh;
    }
    .divide{
        display:none;
    }

    .welcome-title{
        font-size:28px;
    }
    .bgprofileSetting{
     
        min-width: 25vw;
    }
    .pips-icon {
        width: 35%;
    }
    
}


@media (min-width: 540px) and (max-width: 667px) {
    .big-number{        
        font-size: 18px;
    }

    .small-desc{
        font-size : 12px;
    }

    .w-70{
        width: 80%;
    }
    
    // .cardBg{
    //     min-height: 25vh;
    // }

    .auth-input-label{
        font-size: 14px;
    }
    .input{
        font-size:14px;
    }
    .card-desktop{
        display:none;
    }
    .card-mobile{
        display:block;
    }
    .divider{
        display:none;
    }

  
    .welcome-title{
        font-size: 20px;
    }
     .bgprofileSetting{
     
        min-width: 48vw;
    }
    .investHistory-desktop{
        display:none;
    }

    .investHistory-mobile{
        display:block;
    }
   
   
}


@media (min-width: 301px) and (max-width: 539px) {
    .w-70{
        width: 90%;
    }

    .auth-input-label{
        font-size: 12px;
    }
    .input{
        font-size:12px;
    }
    .card-desktop{
        display:none;
    }
    .card-mobile{
        display:block;
    }
    .card-dashboard-content{
        min-height: 25vh;
    }
    .divide{
        display:none;
    }
    .bgprofileSetting{
      
    }
    .welcome-title{
        font-size: 20px;
    }
     .bgprofileSetting{
        min-width: 50vw;
    }
    .investHistory-desktop{
        display:none;
    }

    .investHistory-mobile{
        display:block;
    }
  
    
}

@media (max-width: 300px) {
    .card-small-title{
        font-size : 12px;
    }

    .big-number{        
        font-size: 16px;
    }

    .small-desc{
        font-size : 10px;
    }

    .w-70{
        width: 90%;
    }
    
    // .cardBg{
    //     min-height: 35vh;
    // }

    .card-icon{
        width: 40%;
    }

    .auth-input-label{
        font-size: 12px;
    }
    .card-desktop{
        display:none;
    }
    .card-mobile{
        display:block;
    }
    // .cardBg{
    //     min-height: 20vh;
    // }
    .divide{
        display:none;
    }
    .bgprofileSetting{
        transform: translate3d(-100px, 78px, 0px) !important;
    }
    .welcome-title{
        font-size: 16px;
    }
    .bgprofileSetting{
        min-width: 55vw;
    }
    .investHistory-desktop{
        display:none;
    }
    .investHistory-mobile{
        display:block;
    }
    
}

@media (max-width: 47.9375rem){
    .deznav {
        left: 0;
        top: 5.7rem;
    }
}
.ai-icon{
    padding-left: 0px !important;
}

.metismenu > li{
    border-left: 5px solid rgba(255,255,255,0);
} 

.mm-active {
    color: #DF000C;
}

.selected-lang-bg{
    background: linear-gradient(78.66deg, rgba(37, 198, 198, 0.2) 31.46%, rgba(37, 198, 198, 0) 92.72%);
}

a:hover {
    color: #DF000C;
}
.jsgrid-grid-body::-webkit-scrollbar-thumb, .dataTables_scrollBody::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
    background: linear-gradient(to right,#116466 ,#25C6C6 );
}

.jsgrid-grid-body::-webkit-scrollbar, .dataTables_scrollBody::-webkit-scrollbar, .table-responsive::-webkit-scrollbar {
    background-color: transparent;
}

.investmentAmountRemark{
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-weight: 5;
    font-size: 13px;
    line-height: 14px;
    color: rgba(235, 235, 235, 0.7);
}

.transfer-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
}

.transfer-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 96.3%;
    /* identical to box height, or 11px */
    text-align: center;
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;
}

.view-details{
    cursor: pointer;
}

.row.vertical-divider {
    overflow: hidden;
  }
  .row.vertical-divider > div[class^="col-"] {
    // border-left: 2px solid rgba(235, 235, 235, 0.2);
    // border-right: 2px solid rgba(235, 235, 235, 0.2);
  }
  .row.vertical-divider div[class^="col-"]:first-child {
    border-left: none;
  }
  .row.vertical-divider div[class^="col-"]:last-child {
    border-right: none;
  }
// .css-u6atks-menu{
//     background: linear-gradient(180deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0.065) 100%) !important;
//     backdrop-filter: blur(6px) !important;;
//     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
// }



.mobile-team-tree{
    ul { list-style-type: none;  }
    label{
        border-radius: 5px;
        padding-top: 3px;
        padding-right: 3px;
        padding-left: 3px;
        color: white;	
        margin-bottom: 0px !important;
        display: flex;
        align-items: center;
        .member-name{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: #DDB590;
        }
        @media screen and (max-width:767px) {
            display: block;
        }
    }
    li { 
        margin: 10px;
        // padding: 5px;
        // border: 1px solid #ABC;
        border-radius: 5px;
        
    }
    input[type=checkbox] { display: none; }
    input[type=checkbox] ~ ul { 
        max-height: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        // white-space:nowrap;
        -webkit-transition:all .5s ease;  
        -moz-transition:all .5s ease;  
        -o-transition:all .5s ease;  
        transition:all .5s ease;  
    }
    input[type=checkbox]:checked ~ ul { 
        max-height: 100%;
        max-width: 100%;
        opacity: 1;
    }
    input[type=checkbox] + label:before{
        content: '';
        background: url('../images/team/tree-right-chevron.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transform-origin: 25% 50%;
        margin-left: -5%;
        width: 10px;
        height: 10px;
        // border: 8px solid transparent;
        // border-width: 8px 12px;	
        // border-left-color: white;
        transform: rotate(-90deg);
        display: inline-block;
        text-align: center;
        // content: '';
        color: #AAAFAB;
        -webkit-transition:all .5s ease;  
        -moz-transition:all .5s ease;  
        -o-transition:all .5s ease;  
        transition:all .5s ease; 
        position: absolute;
        margin-top: 5px;

        @media screen and (min-width: 1000px) {
            margin-left: -1%;
        }
    }
    input[type=checkbox]:checked + label:before {
        transform: rotate(0deg);
        /*margin-top: 6px;
    margin-left: -25px;*/
    }
    
}
.transaction-history{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #012060;
}
.small-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #012060;
}

.verifyCodeSentText{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ADB9B9;
    cursor: pointer;
}

.transactionPassword-title-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 43px;
    color: #25C6C6;
}

.kycICTitle{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #012060;

}

.kycICdesc{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    color: #012060;
}

.borderCorner{
    width: 180px;
    height: 120px;
    background:
    linear-gradient(to right, white 2px, transparent 4px) 0 0,
    linear-gradient(to right, white 2px, transparent 4px) 0 100%,
    linear-gradient(to left, white 2px, transparent 4px) 100% 0,
    linear-gradient(to left, white 2px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, white 2px, transparent 4px) 0 0,
    linear-gradient(to bottom, white 2px, transparent 4px) 100% 0,
    linear-gradient(to top, white 2px, transparent 4px) 0 100%,
    linear-gradient(to top, white 2px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 12px 13px;
}

.fund-number{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #012060;
}

.monthlydesc{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;
    color: #012060;
    opacity: 0.7;
}

.worldwidebg{
    background-image: url(../images/fund/worldwide.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    width:100%;
    min-height: 35vh;
    @media (min-width:1500px){
        min-height: 50vh;
    }
}

.graph{
    width:80%;
}

.css-5sz5u5-singleValue{
    color: #363848 !important;
}

.row {
    &.display-flex {
        // display: flex;
        // flex-wrap: wrap;
        display: table;

        [class*='display-flex-col'] {
            // flex-grow: 1;

            float: none;
            display: table-cell;
            vertical-align: top;
        }
    }
}

.display-flex-col-card{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .card{
        flex-grow: 1;
    }
}

.comingSoonText {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #012060;

    font-size: calc(30px + 0.02vw);
        line-height: calc(21px + 0.02vw);
        @media screen and (max-width: 767px) {
            font-size: calc(25px + 0.1vw);
            line-height: calc(35px + 0.1vw);
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            font-size: calc(30px + 0.2vw);
            line-height: calc(21px + 0.2vw);
        }

}


.greyBg{
    background: linear-gradient(220.15deg, rgba(255, 255, 255, 0.74) 21.15%, #CAD9FF 109.06%);
}
.min-val{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 89.8%;
    /* identical to box height, or 12px */

    text-transform: capitalize;

    color: #242C2C;
}
.investment-amount{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */

    text-transform: uppercase;

    /* Linear */

    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}


.investment-product{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 52px;
    /* leading-trim and text-edge are draft CSS properties.
    
    Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
    */
    text-transform: uppercase;
    
    /* Linear */
    
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.back-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    text-transform: capitalize;

    /* #363848 */
    text-decoration:underline;
    color: #363848;
}

.status{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 96.3%;
    text-transform: uppercase;
    &.ACTIVE{
        color: #29ABE2;
    }
    &.PENDING{
        color: #DDB590;
    }
    &.EXPIRED{
        color: #A0A9A9;
    }
    &.CANCELLED{
        color: #FF6666;
    }
    &.COMPLETED{
        color: #25C6C6;
    }
    &.FAILED{
        color: #DF000C;;
    }
}
.btn-responsive{
    width: 50%;
    @media (max-width:1024px){
        width:120px;
    }
    @media (max-width: 768px) {
        width: 125px;
    }
}

.withdrawal-capital-btn{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    box-shadow: 0px 0px 5px rgba(1, 32, 96, 0.35);
    border-radius: 107px;
    cursor: pointer;
}
.withdrawal-btn-capital-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    /* identical to box height, or 108% */
    text-align: center;
    color: #FFFFFF;
}

.withdrawal-btn{
    border: 1px solid #000063;
    filter: drop-shadow(0px 0px 5px rgba(1, 32, 96, 0.35));
}

.withdrawal-btn-text{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.withdrawal-btn-text-topUp{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.unsubsribe-btn{
    background: #363848;
    box-shadow: 0px 0px 5px rgba(1, 32, 96, 0.35);
}
.unsubsribe-btn-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    /* identical to box height, or 108% */
    text-align: center;
    /* #CCCCCC */
    color: #787878;
}
.withdrawal-req{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 105%;
    /* identical to box height, or 14px */
    color: #B22D38;
    @media screen and (max-width:767px) {
        font-size: 10px;
    }
}

.withdraw-tc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */
    text-transform: capitalize;
    /* #CCCCCC */
    color: #CCCCCC;
}

.withdraw-tcdesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* #CCCCCC */
    color: #CCCCCC;
    @media screen and (max-width:767px) {
        text-align:center;
    }
}




.input-blue{
    background: transparent;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: #000063 !important;
    &:focus, &:hover{
        background: transparent;
    }
}
.showTextBtn{
    background:transparent;
    border: none;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.input-group {
    border-radius: 107px;
    overflow: hidden;
    align-items: center !important;
    border: 1px solid #363848;

    &:hover,
    &:focus,
    &:active {
        border: 1.5px solid #000063;
    }

    .icon {
        width: 60%;
    }
    .icon2{
        width: 50%;
    }
}

.input-transparent {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
}


.slick-dots{
    bottom: -30px;
    left: -20px;
}

#carousel .slick-dots{
    position:static;
}

.slick-dots li {
    margin: 0 -3px;
}



.carousel-control-prev-icon{
    @media screen and (max-width:767px) {
        display:none;
    }
}
.carousel-control-next-icon{
    @media screen and (max-width:767px) {
        display:none;

    }  
} 

.carousel-indicators
.slick-dots li.slick-active button:before {
    color:#DF000C;
}

.profileIcon-div{
    left:0;
    right:0;
    top:-10%;
    margin:auto;
    position:absolute;
    width: 50%;
    text-align: center;
}

.profileCloseBtn{
    position: fixed;
    top: 4%; 
    right: 5%;
}


// #sliderQuicklinks .slick-track {
//     transform: translate3d(-300px, 0px, 0px) !important;
// }

.css-9gakcf-option{
    background-color: #EBEBEB !important;
    color:#000063 !important;
}

.it-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-align: right;
    text-transform: capitalize;
    /* 000063 */
    color: #000063;

    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}

.dropdown_history {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle_history {
  
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dropdown-toggle_history i {
    margin-left: 10px;
  }
  
  .dropdown-menu_history {
    position: absolute;
    top: 110%;
    left: 0%;
    z-index:2;
    display: block;
    padding: 10px 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 1.375rem;
    // min-width: 9rem;
    right: 0%;
    // @media screen and (max-width:767px) {
    //     left: -60px;
    // }
  }
  
  .dropdown-menu_history li {
    padding:5px;
    cursor: pointer;
    text-align:center;
  }
  
  .dropdown-menu_history li:hover {
    background-color: #f0f0f0;
  }

  .dropdown-box{
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    width:220px;

    @media screen and (max-width:767px) {
        width:auto;
    }
  }

  .arrow-down{
    width:6%;
  }

  .team-icon{
    width:50%;

    @media screen and (max-width:767px) {
        width:70%;
    }
  }

  .input-grey{
    background: #F8F8F8;
    border-radius: 16px;
  }

  .css-1wa3eu0-placeholder{
    @media screen and (max-width:767px) {
        top:32% !important; 
    }
   
  }

  .css-2dxebr-singleValue{
    @media screen and (max-width:767px) {
        top:35% !important; 
    }
  }

  .css-tlfecz-indicatorContainer{
    @media screen and (max-width:767px) {
        padding: 0px !important;
        margin-bottom:8px;
    }
  }

  #kyc{
    .css-1wa3eu0-placeholder{
        @media screen and (max-width:767px) {
            top:50% !important; 
        }
    
       
      }
    
      .css-t5y1ar-control{
        @media screen and (max-width:767px) {
            height: 2.75rem !important;
            }
        }   
    
        
      .css-g1d714-ValueContainer{
        padding:2px 8px !important;
    
        @media screen and (max-width:767px) {
            padding:0px 8px !important;
        }
      }   
    
      
      .css-tlfecz-indicatorContainer{
        @media screen and (max-width:767px) {
            padding: 0px !important;
            margin-bottom:0px;
        }
      }
      .css-max98f-control{
        height:3.5rem !important;

        @media screen and (max-width:667px) {
            height:2.75rem !important;
        }
      }
      .css-1hwfws3{
        padding: 1px 8px !important;
     }
     .css-109onse-indicatorSeparator{
        background-color: transparent;
     }
  }
  
  [data-sidebar-style=full][data-layout=vertical] .menu-toggle .header{
    padding-left: 6.5rem !important;
  }

  [data-sidebar-style="full"] .header, [data-sidebar-style="overlay"] .header {
    padding-left: 17rem;
    @media screen and (max-width:767px) {
        padding-left: 0px;
    }
}




@media screen and (max-width: 480px){
    [data-sidebar-position=fixed][data-layout=vertical] .deznav, [data-sidebar-position=fixed][data-layout=vertical] .nav-header {
        border-right: none!important;
        width: 0;
    }
}

.mobile-logo{
    @media screen and (max-width:767px) {
        width:40px;
        height:40px;
    }
}

.header .header-content{
    @media screen and (max-width:767px) {
        padding-left: 8rem;
    }
}

.smallCloseBtnModal{
    right: 5%; 
    top: 3%;
    z-index:99;
}

.test{
    right: 5%; 
    top: 10%;
}

.minWidth{
   text-align: center;
   width:69px;
    
}

.MuiInput-underline:after{
    display:none !important;
}
.MuiInput-underline:before{
    display:none !important;
}


.MuiInputBase-input{
    padding: 6px 20px 7px !important;
    line-height: 1.5em !important;
    font-size: 1em !important;
    color: #1b1b1b !important;
}


.custom-input::placeholder{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: rgba(54,56,72, 0.6) !important;
    @media screen and (max-width:767px) {
        font-size: 11px;
    }
}

.custom-datetime-input {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 14px;
    color: #333;
  }


  .MuiFormControl-root.MuiTextField-root {
    display: inline-grid;
}


// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {

//     border-color:transparent;
// }

// .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
//     border-color:transparent !important;

// }

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
//     border-color:transparent !important;
// }

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
//     border: 0px !important;
// }

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
//     border-color:transparent !important;
// }


.text-elip{
    @media screen and (max-width:767px) {
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#gas{
    .accordion-body-text{
        @media screen and (max-width:767px) {
            border-top: 1px solid #CCCCCC;;
        }
    
    }
}
.custom-datetime-picker:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
}


.custom-datetime-picker .MuiOutlinedInput-notchedOutline {
    border-color:transparent !important;
    border-width: 0px !important;
}


.css-g1d714-ValueContainer{
     padding:0px !important;
}

.bgTopupInvest{
    background: #FAFAFA;
}

.linkHover{
    &:hover{
        color: #DF000C !important;
    }
}

.css-1hwfws3{
    padding:0px !important;
}

#selectWithdrawal{
    .css-1wa3eu0-placeholder{
        @media screen and (max-width:767px) {
            top:50% !important; 
        }
    
       
      }
      .css-1numzsg-control{
        @media screen and (max-width:767px) {
            padding:6px;
        }
      }
    
      .css-t5y1ar-control{
        @media screen and (max-width:767px) {
            height: 2.75rem !important;
            }
        }   
    
        
      .css-g1d714-ValueContainer{
        padding:2px 12px !important;
    
        @media screen and (max-width:767px) {
            padding:0px 12px !important;
        }
      }   
    
      
      .css-tlfecz-indicatorContainer{
        @media screen and (max-width:767px) {
            padding: 0px !important;
            margin-bottom:0px;
        }
      }
      .css-1hwfws3{
        padding: 1px 12px !important;
     }
       
}

#country {
 .css-1wa3eu0-placeholder{
    @media screen and (max-width:767px) {
        top:50% !important; 
    }

   
  }

  .css-t5y1ar-control{
    @media screen and (max-width:767px) {
        height: 2.75rem !important;
        }
    }   

    
  .css-g1d714-ValueContainer{
    padding:2px 8px !important;

    @media screen and (max-width:767px) {
        padding:0px 8px !important;
    }
  }   

  
  .css-tlfecz-indicatorContainer{
    @media screen and (max-width:767px) {
        padding: 0px !important;
        margin-bottom:0px;
    }
  }
  .css-1hwfws3{
    padding: 1px 8px !important;
 }
   

}


.nav-header{
    @media only screen and (max-width: 850px) and (orientation: landscape){
        width:0rem !important;
    }
}

.css-1gtu0rj-indicatorContainer{
    @media screen and (max-width:767px) {
        padding:0px !important;
        // margin-bottom:8px;
    }
}
.css-1numzsg-control{
    @media screen and (max-width:767px) {
        height:2.75rem !important;
    }
}
.overflow-wrap-anywhere{
    overflow-wrap: anywhere;
}

.header-right .header-profile .nav-link {
    margin-left: 0px !important;
    padding-left:0px !important;
}
#leverage{
    .css-1wa3eu0-placeholder{
        top:60% !important;
        @media screen and (max-width:667px) {
            top:38% !important;
        }
    }
    .css-6q0nyr-Svg {
      margin-top: 5px;
      @media screen and (max-width:667px) {
        margin-top: unset;
      }

    }
    .css-1gtu0rj-indicatorContainer{
        @media screen and (max-width:767px) {
            padding:0px !important;
            margin-bottom:5px;
        }
    }
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    pointer-events: none;
    opacity:1 !important;
}
