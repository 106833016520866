.investment{
    .wallet-icon{
        width: 60%;
        filter: drop-shadow(0px 4px 10px rgba(38, 161, 123, 0.8));
    }
    .package-card{
        // background: linear-gradient(220.15deg, rgba(255, 255, 255, 0.5) 21.15%, #CAD9FF 109.06%);
        border-radius: 16px;
        transition: all 1s ease-in;
        border: 1px solid rgba(175,12, 21, 0.6);
        &:hover, &:active, &:focus, &.active{
            background: radial-gradient(74.54% 81.26% at 109.76% -57.08%, rgba(255, 0, 0, 0.365) 0%, rgba(255, 124, 124, 0.065) 100%);
            box-shadow: 0px 7px 15px #9EB9FD;
        }
    }
    .packageName{
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .descriptionBox{
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    }

    .tradeTypebox{
        background: #DF000C;
        border-radius: 0px 80px 80px 0px;
        position:absolute;
        top: -5%;
        margin-left:-1px;
    }

    .InvestmentManualBtn{
        background: #DF000C;
        border-radius: 50px;
        width:130px;
    }
    .InvestmentManualTradeType{
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

    }
    .prevArrow{
        cursor: pointer;    
        @media screen and (max-width: 500px) {
            top: 35%; 
            left: -1%;
         
        }
    }
    
    .nextArrow{
       
        cursor: pointer;
        @media screen and (max-width: 500px) {
            top: 35%; 
            right: -1%;
        }
    }

    .arrowColor{
        background: -moz-linear-gradient(top, #e72c83 0%, #a742c6 100%);
        background: -webkit-linear-gradient(top, #e72c83 0%, #a742c6 100%);
        background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

