.wallet{
    .wallet-name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 107%;
        /* or 19px */
        text-transform: uppercase;
        /* 000063 */
        color: #000063;
    }
    .wallet-desc{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        text-transform: capitalize;

        color: #000000;
    }
    // .big-number{
    //     font-family: 'Poppins', sans-serif;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 28px;
    //     line-height: 33px;
    //     text-align: right;
    //     text-transform: uppercase;
    //     color: #EBEBEB;
    // }
    .currency{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-transform: uppercase;
        color: #012060;
    }
}

.walletHistory{
    td{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    td:not(:last-child){
        border-right: 1px solid rgba(235, 235, 235, 0.2);;
    }
}

.deposit-description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 96.3%;
    /* identical to box height, or 13px */
    text-transform: uppercase;
    color: #A9A9A9;
    
}

.deposit-desc-info{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190%;
    /* or 19px */
    color: #A9A9A9;
}

.wallet-accordion-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 107%;
    /* identical to box height, or 26px */
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
}

.deposit-accordion{
    .accordion-header{
        border: none !important;
    }
    .accordion__body{
        border: none !important;
    }
    .divider{
        display: flex;
        width: 2px;
        height: 90%;
        border: 1px solid rgba(235, 235, 235, 0.5);
        @media screen and (max-width: 767px){
            display:none;
        }
    }
    .arrow{
        width: 30%;
    }
}

.accordion-header-primary{
    width:100%;
    background:transparent;
}
.qr-container{
    background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #af0c15 0.81%, #000063 89.86%) border-box;
    border: 1px solid transparent;
    border-radius: 14px;
    width: 100%;
}

.deposit-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-transform: capitalize;
    /* #D9D9D9 */
    color: #181818;
}

.deposit-value{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 96.3%;
    /* or 15px */
    text-align: right;
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
    word-break: break-all;
}

.css-yk16xz-control{
    background-color: transparent !important;
}
.css-1pahdxg-control{
    background-color:transparent !important;
    border:none !important;
    box-shadow:none !important;
    border: 1px solid;
}
.css-26l3qy-menu{
    border: 1px solid;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)!important;
}

.transfer{
    img{
        width: 35%;
        // filter: drop-shadow(0px 4px 10px #1E9C9D);
    }
    
    .transfer-label{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        text-transform: uppercase;
        color: #181818;
        display: block;
    }
    .transfer-value{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #000063;
    }
    .input-transparent{
        background: transparent;
    
        border: 1px solid #FFFFFF;
        border-radius: 116px;
        &:hover, &:focus{
            background: transparent;
        }
        &::placeholder{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            color: #FFFFFF;
          
            text-transform: capitalize;
        }
    }
    .divider{
        width: 2px;
        height: 100%;
        border: 1px solid rgba(235, 235, 235, 0.5);
    }
}

.receiver-will-get{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #EBEBEB;
    opacity: 0.7;
    display: block;
}

.receiver-get-amount{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #EBEBEB;
    display: block;
}

.view-details{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    /* 000063 */
    color: #000063;
    
}
.wallet-big-number{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    text-align: right;
    text-transform: uppercase;
    /* 000063 */
    color: #000063;
}
.cardcurrencyBg{
    background: #F4F4F4;
    backdrop-filter: blur(21px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 13px;
}
@media (max-width: 912px) {
    .transfer{
        img{
            width: 28%;
        }
    }
    .transfer{
        .divider{
            border: none;
        }
    }
}
@media (max-width: 540px) {
    .wallet .wallet-name{
        font-size:14px;
    }
    .view-details {
        font-size:10px;
    }
    .wallet-icon{
        width:100%;
    }
}

@media (max-width:300px){
    .wallet .wallet-name{
        font-size:14px;
    }
}

.withdraw-btn{
    background: linear-gradient(86.87deg, #CD9564 1.86%, #FFCB9A 94.58%);
    box-shadow: 0px 4px 15px rgba(221, 181, 144, 0.5);
    border-radius: 107px;
    display: inline-block;
}

.withdraw-btn-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #303030;
    text-transform: capitalize;
}

.bonus-name{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #DDB590;
}

.mobile-icon{
    width:40px;
    @media screen and (max-width:767px) {
        width:10%;
    }
}

.wallet-icon{
    width:40%;

    @media screen and (max-width:767px) {
        width: 60px;
        height:60px !important;
        object-fit: contain;

    }
}


.form-control{
    @media screen and (min-width:1000px) {
        height:3.5rem !important;
    }
}

#internalTransfer{
    @media screen and (min-width:992px) {
        .css-1wa3eu0-placeholder {
            top: 58%;
        }
    }
   
}

    .ant-picker-panels{
        @media screen and (max-width:667px) {
            flex-direction: column;
        }
       
    }
    
    .ant-picker-separator{
        display:flex !important;
        align-items: center !important;
    }

    .ant-picker-input >input{
        text-align: center;
    }

.copy-img{
    width:30px;

   
}

.currency{
    color: var(--d-9-d-9-d-9, #181818);
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 96.3%; /* 15.408px */
    text-transform: capitalize;
}

.cTraderID-Title{
    color: var(--000063, #000063);
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
   

}
.cTraderID{
    color: var(--000063, #000063);
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    

}