.announcement-date{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #181818;
}

.announcement-header{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 19px;
    text-transform: uppercase;
    color: #012060;

    @media screen and (max-width:767px) {
        font-size: 13px;    
    }
}

.announcement-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #181818;
}

.quicklinks-img{
    width: 60%;

    @media screen and (min-width:768px) and (max-width:991px) {
        width: 30%;
    }
}

.quickLinks-width{
    
    width:70px;
}

.quicklinks-name{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #363848;
    text-transform: capitalize;
}

.referral-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #181818;
    text-transform: uppercase;
}

.referral-box{
    background: linear-gradient(180deg, rgba(249, 249, 249, 0.15) 0%, rgba(249, 249, 249, 0.0975) 100%);
    box-shadow: 0px 4px 16px rgba(24, 55, 51, 0.5);
    border-radius: 25px;
}

.referral-value{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #181818;
}

.welcome-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
@media (max-width: 912px) {
    .p-left {
        padding-left: 0px;
    }

}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .referral-box{
        border-radius: 15px;
    }
    
    .referral-box-content{
        padding:18px;
        
      }
    
}




@media only screen and (min-width: 360px) and (max-width: 541px) {
    .referral-box{
        border-radius: 15px;
    }
    .quicklinks-img{
        width: 60%;
        
    }

   

    
}
@media (max-width: 300px) {
    .referral-box{
        border-radius: 15px;
    }
 
}

#dashboard_carousel{
    .carousel-control-prev, .carousel-control-next {
        width: 10% !important;
    }
   
}

.dashbaord-carousel-img{
    border-radius: 15px;
}


.bannerBg{
    background-color: #ffffff;
    background-image: url('../images/dashboard/bannerDash.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height:185px;
    border-radius: 15px;
    @media screen and (min-width:768px) and (max-width:991px) {
        min-height:150px;
    }

    @media screen and (max-width:767px) {
        min-height:115px;
        background-image: url('../images/dashboard/bannerDashmobile.png');
    }
}

.dashboard-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 106%;
    /* or 26px */

    letter-spacing: 0.02em;

    background: linear-gradient(87.52deg, #FF7A00 -0.7%, #FF2E00 130.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    
    @media screen and (max-width:767px) {
        font-size: 25px;
    }
}

.dashboard-subtitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    /* identical to box height, or 17px */


    color: #CBCBCB;

    text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
}

.red-btn{
    cursor: pointer;
    background: #DF000C !important;
    box-shadow: 0px 0px 37px rgba(1, 32, 96, 0.35);
    border-radius: 107px;
    transition: all 1s ease;
    color: #ffffff;
    &:hover{
        background: linear-gradient(186.87deg, #000063 1.86%, #AF0C15 94.58%);
    }
}

.btn-started{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
    @media screen and (max-width:767px) {
        font-size: 13px;
    }
}

.dashboardCardTop-height{
    min-height: 500px;

    @media screen and (max-width:767px) {
        min-height: 0px;
    }
}

.dashboardCardBottom-height{
    min-height: 240px;

    @media screen and (max-width:767px) {
        min-height: 0px;
    }
}