.authHeader {
    .logoImg {
        width: 20%;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .lang-dropdown {
        background: linear-gradient(90deg, #329DEB -0.1%, #141458 99.9%);
        border-radius: 46px;
        &:hover, &:active, &:focus{
            background: var(--primary-color-1);
        }
    }
    .flag{
        width: 25px;
    }

    .lang-dropdown-menu-lg {
        right: 0;
        display: none;
        border-radius: 10px;
        margin-top: 8px;
        width: 15vw;
        padding: 10px;
        background: #0B0B0D;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: scroll;
        border: 2px solid rgba(80, 195, 197, 0.4);
        box-shadow: 0px 11px 39px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .lang-dropdown-menu-lg span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .lang-menu-container {
        height: auto;
        overflow-y: scroll;
        flex-direction: column;
        display: flex;

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .lang-dropdown-menu-lg span:hover {
        background: #ffffff;
        color: #181818;
        border-radius: 10px;
    }

    #openDropdown-lg:checked+.lang-dropdown-menu-lg {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
    #languageDropdown {
        .notification_dropdown .dropdown-menu-end {
            min-width: 150px !important;
            transform: translate3d(-12px, 67px, 0px) !important;
        }
    }
    
    #auth
    .show.dropdown-menu-right{
        transform: translate3d(0px, 35px,0px) !important;
     
    }
    .language-bar{
        background: #FFFFFF;
        border-radius: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        width:150px;
    }

    .langmain-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        /* identical to box height */
        color: #012060;
    
    }

    .bgGlass {
        background: #fff;
        border: 1px solid #fff;
        border-radius: 16px;
      
    }
    
}