.header-logo{
    width: 45%;
}
.header-logo-r{
    width: 40%;
}

.nav-header{
    background-color: #ffffff !important;
    box-shadow: 5px -6px 6px 2px rgba(8, 31, 34, 0.2);
-webkit-box-shadow: 5px -6px 6px 2px rgba(8, 31, 34, 0.2);
-moz-box-shadow: 5px -6px 6px 2px rgba(8, 31, 34, 0.2);
}

.hamburger{
    .line{
        background: #363848 !important;

        @media screen and (max-width:767px) {
            background: white !important;

        }
    }
}

.scrollbar-container.deznav-scroll{
    background-color: #ffffff;
    box-shadow: 5px 1px 6px 0px rgba(8, 31, 34, 0.2);
    -webkit-box-shadow: 5px 1px 6px 0px rgba(8, 31, 34, 0.2);
    -moz-box-shadow: 5px 1px 6px 0px rgba(8, 31, 34, 0.2);
}