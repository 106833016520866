.settingOption{
    // height: 200px;
    border: 1px solid #012060;
    backdrop-filter: blur(21px);
    border-radius: 16px;
}

.settingWallet-img{
    width: 30%;
}

.settingEmail-img{
    width:30%;
}


.settingBox{
    left: 0;
    // width: 90% !important;
    // height: 70vh;
    border-radius: 16px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}



.headerText{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #DDB590;
    text-transform: uppercase;
}

.settingContent{
    padding-left: 2rem;
}

.labelText{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    text-transform: capitalize;
    color: #181818;

}

.input-setting{
    height: 34px;
    opacity: 0.4;
    border: 1px solid #FFFFFF;
    border-radius: 50px !important;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important;
}


 .UpdateBtn{
    background: linear-gradient(86.87deg, #116466 1.86%, #25C6C6 94.58%);
    box-shadow: 0px 4px 37px rgba(37, 198, 198, 0.35);
    border-radius: 107px;
    width: 10vw;
    height: 5vh;
}

.UpdateBtnText{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.settingContainerBox{
    left: 0;
    // width: 1044px;
    // height: 58vh;
    border-radius: 16px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.ResendCode{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #012060;
}

.Resend{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #DDB590;
}

.settingWalletBox{
    left: 0;
    // width: 1044px;
    // height: 40vh;
    border-radius: 16px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}


.showVerificationTextBtn{
    background: transparent;
    box-shadow: none;
    border: none;
}
.showVerificationText{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 0.6;
    margin-left: 10px;
   
    @media (max-width:300px){
        font-size: 10px;
        line-height: 12px;
    }
}



@media (max-width: 575px) {
    .settingBox{
        left: 0;
        // width: 100vw;
        // height: 70vh;
        border-radius: 16px;
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .UpdateBtn{
        background: linear-gradient(86.87deg, #116466 1.86%, #25C6C6 94.58%);
        box-shadow: 0px 4px 37px rgba(37, 198, 198, 0.35);
        border-radius: 107px;
        width: 40vw;
        height: 5vh;
    }
    
    .UpdateBtnText{
        color: #FFFFFF;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .settingContainerBox{
        left: 0;
        width: 100vw;
        // height: 70vh;
        border-radius: 16px;
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .settingWalletBox{
        left: 0;
        width: 100%;
        // height: 35vh;
        border-radius: 16px;
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    }
    
    

    

}
@media (min-width:768px) and (max-width: 991px) {
    .settingBox{
        left: 0;
        // width: 84vw;
        // height: 70vh;
        border-radius: 16px;
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .UpdateBtn{
        background: linear-gradient(86.87deg, #116466 1.86%, #25C6C6 94.58%);
        box-shadow: 0px 4px 37px rgba(37, 198, 198, 0.35);
        border-radius: 107px;
        width: 25vw;
        height: 4vh;
    }
    
    .UpdateBtnText{
        color: #FFFFFF;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .settingWalletBox{
        left: 0;
        width: 100%;
        // height: 26vh;
        border-radius: 16px;
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    }
    
}

.empty-img{
    width: 10% !important;
    @media screen and (max-width: 767px){
        width: 30% !important;
    }
}

.camera-img{
    position: absolute;
    bottom: 0;
    right: 45%;
    width: 4% !important;
    @media screen and (max-width: 767px){
        right: 35%;
        width: 10% !important;
    }
}

.passport-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #012060;
}

.setting{
    .card-black{
        background: #FFFFFF;
        box-shadow: 0px 3px 8px #C1BDBD;
        border-radius: 16px;
        transition: all 1s linear;
        &:hover{
            background: linear-gradient(228.7deg, #AF0C15 0.57%, #000063 98.8%);
            box-shadow: 0px 3px 8px #C1BDBD;
            border-radius: 16px;
            .settingText{
                color: white;
            }
            .arrow{
                color: white;
            }
        }
        .arrow{
            color: #000063;
        }
        .settingText{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 107%;
            /* or 19px */
            text-transform: uppercase;
            /* 000063 */
            color: #000063;

            @media screen and (max-width:767px) {
                font-size: 14px;
            }
        }
    }
}
.document{
    width: 150px;
    height: 150px !important;
}

.setting-img{
    width: 90px;
 
}

.headerText{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    text-transform: uppercase;

    @media screen and (max-width:767px) {
        font-size: 20px;
    }
}

.text-grey-1{
    color: #181818;
}

.text-blue-1{
    color: #000063;
}



.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-16 {
    font-size: 1.111vw;
    line-height: 1.667vw;

    @media screen and (max-width: 767px) {
        font-size: 2.611vw;
        line-height: 105%;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.611vw;
        line-height: 120%;
    }
}

.font-20{
    font-size: 1.389vw;
    line-height: 105%;

    @media screen and (max-width: 767px) {
        font-size: 2.889vw;
        line-height: 105%;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.889vw;
        line-height: 105%;
    }
}