.metismenu{
    .nav-text{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #012060;
        margin-left: 5px;

        @media screen and (max-width:1400px) {
            font-size:13px;
        }
    }
   
    .menu-link{
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .mm-active{
        background: linear-gradient(90deg, #000063 3.04%, rgba(0, 0, 99, 0.510417) 69.64%, rgba(0, 0, 99, 0.21) 99.91%);
        a{
            background: none !important;
            box-shadow: none !important;
            &::after{
                border-top-color: #012060 !important;
                border-left-color: #012060 !important;
            }
        }
        .nav-text{
            font-family: 'Poppins', sans-serif;
            background: #FFFFFF;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            
          
        }
        .menu-icon{
            color: #FFFFFF !important;
        }
    }
    .submenu-text:hover, .submenu-text:focus{
        color: #DF000C;
    }
    .menu-active{
        .submenu-text{
            font-family: 'Poppins', sans-serif;
        }
    }
    .mm-parent-active{
        // background: linear-gradient(90deg, #000063 3.04%, rgba(0, 0, 99, 0.510417) 69.64%, rgba(0, 0, 99, 0.21) 99.91%);
        border-left: 5px solid #DF000C;;
    }
    ul a:before{
        content: url('../images/menu/chevron-right.png') !important;
        top: 20% !important;
        background: none !important;
    }
    a:hover{
        color: #DF000C !important;
    }
}


.metismenu{ //#DF000C
    .main-menu{
        .nav-text, .menu-icon{
            color: #000063;
        }
        &:hover{
            background: linear-gradient(90deg, #000063 3.04%, rgba(0, 0, 99, 0.510417) 69.64%, rgba(0, 0, 99, 0.21) 99.91%);
            border-left-color: #DF000C;
            .menu-icon, .nav-text{
                color: #ffffff;
            }
        }
    }
}