.support {
    position: fixed;
    bottom: 0%;
    right: 3%;
    z-index: 0;

    .btn-telegram {
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        
        &:hover,
        &:active,
        &:focus {
            background: linear-gradient(186.87deg, #000063 1.86%, #AF0C15 94.58%);
        }
    }
    .support-icon{
        width: 2.5em;
        height: auto;
        aspect-ratio: 1;
    }
    .con-tooltip {
        position: relative;
        border-radius: 9px;
        padding: 0 10px;
        margin: 10px;
        display: inline-block;
        transition: all 0.3s ease-in-out;
        cursor: default;
    }
    
    .tooltip {
        visibility: visible;
        z-index: 1;
        opacity: 1;
    
        width: 12em;
        padding: .8em;
        background: #333;
        color: #ffffff;
        position: absolute;
        top: -140%;
        left: -25%;
        border-radius: 9px;
        transform: translateY(9px);
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);

        &.ZH{
            width: 9em;
        }
    }
    
    .tooltip::after {
        content: " ";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 12.5px 0 12.5px;
        border-color: #333 transparent transparent transparent;
        position: absolute;
        left: 20%;
    
    }
    .con-tooltip{
        &:hover, &:active, &:focus{
            .tooltip{
                visibility: visible;
                transform: translateY(-3px);
                opacity: 1;
                transition: .3s linear;
                animation: odsoky 1s ease-in-out infinite alternate;
            }
        }
    }
    @keyframes odsoky {
        0% {
            transform: translateY(5px);
        }
    
        100% {
            transform: translateY(2px);
        }
    
    }
    
    .left .tooltip {
        top: -20%;
        left: -330%;
        &.ZH{
            left: -250%;
        }
    }
    
    .left .tooltip::after {
        top: 40%;
        left: 90%;
        transform: rotate(-90deg);
    }
    
    .left{
        &:hover, &:active, &:focus{
            transform: translateX(-6px);
        }
    }
}




// .login{
//     .support{
//         position: absolute;
//         right: 3%;
//         bottom: 5%;
//     }
// }

// .register {
//     .support{
//         float: right;
//         margin-right: 3%;
//         padding-bottom: 3%;
//     }
// }