:root {
    --primary-color-1: #BD0034;
    --primary-color-2: #1A237B;
    --text-primary: #ffffff;
    --text-seconday: #EBEBEB;
    --text-muted: #999999;
    --terms-text: #A9A9A9;
}

body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    line-height: unset;
    touch-action: pan-x pan-y;
}

[data-sidebar-style="overlay"] {
    .navigation-backdrop-control {
        z-index: 1;
        &.d-none {
            z-index: -1 !important;
        }
    }
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-10 {
    line-height: 110%;
    font-size: 0.625em;
}

.font-12 {
    line-height: 110%;
    font-size: 0.75em;
}

.font-13{
    line-height: 110%;
    font-size: 0.8125em;
}

.font-14 {
    line-height: 110%;
    font-size: 0.875em !important;
}

.font-16 {
    line-height: 1.5em;
    font-size: 1em !important;
    // @media screen and (max-width: 480px) {
    //     font-size: 3.111vw;
    // }

    // @media screen and (min-width: 481px) and (max-width: 768px) {
    //     font-size: 2.611vw;
    // }

    // @media screen and (min-width: 769px) and (max-width: 1024px) {
    //     font-size: 2.111vw;
    // }

    // @media screen and (min-width: 1025px) and (max-width: 1200px) {
    //     font-size: 1.611vw;
    // }

    // @media screen and (min-width: 1201px) {
    //     font-size: 1.111vw;
    // }
}

.font-18 {
    line-height: 110%;
    font-size: 1.125em;
}

.font-19 {
    line-height: 110%;
    font-size: 1.1875em;
}

.font-20 {
    line-height: 110%;
    font-size: 1.25em;
}

.font-22 {
    line-height: 110%;
    font-size: 1.375em;
}

.font-24 {
    line-height: 110%;
    font-size: 1.5em;
}

.font-25 {
    line-height: 110%;
    font-size: 1.5625em;
}

.font-26 {
    line-height: 110%;
    font-size: 1.625em;
}

.font-28 {
    line-height: 110%;
    font-size: 1.75em;
}

.font-30 {
    line-height: 110%;
    font-size: 1.875em;

}

.font-31 {
    line-height: 110%;
    font-size: 1.9375em;

}

.font-32 {
    line-height: 110%;
    font-size: 2em;

}
.font-33 {
    line-height: 110%;
    font-size: 2.0625em;

}
.font-34 {
    line-height: 110%;
    font-size: 2.125em;

}
.font-35 {
    line-height: 110%;
    font-size: 2.1875em;

}
.font-36 {
    line-height: 110%;
    font-size: 2.25em;

}

.font-40 {
    font-size: 2.5em;
    line-height: 110%s;

    // @media screen and (max-width: 767px) {
    //     font-size: 4.278vw;
    //     line-height: 112%;
    // }

    // @media screen and (min-width: 768px) and (max-width: 991px) {
    //     font-size: 3.278vw;
    //     line-height: 109%;
    // }
}

.opacity-05 {
    opacity: 0.6;
}

.opacity-06 {
    opacity: 0.6;
}

.opacity-07 {
    opacity: 0.7;
}

.opacity-08 {
    opacity: 0.8;
}

.opacity-09 {
    opacity: 0.9;
}


.break-white-space {
    white-space: break-spaces;
}

.primary-text {
    color: var(--primary-color-1);
}

a.primary-text {
    color: var(--primary-color-1);
    &:hover {
        opacity: 0.8;
    }
}

a.text-white {
    &:hover {
        color: var(--primary-color-1) !important;
    }
}

.text-black-1 {
    color: #012060;
}

.text-black-2 {
    color : #181818;
}

.text-red-1 {
    color: #B22D38;
}

.text-term{
    color: var(--terms-text);
}

.text-grey-1 {
    color: #181818;
}



.text-grey-2 {
    color: #93969D;
}

.text-blue {
    color: var(--primary-color-2);
}

.text-blue-1 {
    color: #000063 !important;
}

.text-red-1 {
    color: #DF000C;
}

.text-muted {
    color: var(--text-muted);
}



.letter-spacing-03p {
    letter-spacing: 0.3px;
}

.input-group {
    border-radius: 100px;
    overflow: hidden;
    align-items: center !important;
    border: 1px solid #363848;

    &:hover,
    &:focus,
    &:active {
        border: 1.5px solid var(--primary-color-2);
    }

    .icon {
        width: 60%;
    }

    .icon2 {
        width: 50%;
    }

    &.disabled{
        background:#e0e0e0;
    }
}

.input-transparent {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    color: #1b1b1b !important;
}


.input-blue {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    color: #000063 !important;
}

.transparent-bg {
    background: transparent;
}

.requestCodeBtn {
    cursor: pointer;
    background: var(--primary-color-2);
    border: 1px solid var(--primary-color-2);
    border-radius: 100px;
    color: #d9d9d9 !important;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(26, 35, 123, 0.8);
        border: 1px solid rgba(26, 35, 123, 0.8);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(26, 35, 123, 0.4);
    }
}

.primary-btn,
.swal-button--confirm {
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    box-shadow: 0px 0px 8px rgba(1, 32, 96, 0.35);
    border-radius: 100px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #ffffff !important;
    font-weight: 400 !important;
    border: 1px solid var(--primary-color-2) !important;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: linear-gradient(220deg, #AF0C15 -0.1%, #000063 99.9%) !important;
        box-shadow: 0px 0px 37px rgba(1, 32, 96, 0.35);
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}

.default-btn {
    cursor: pointer;
    border-radius: 107px;
    border: 1px solid #AF0C15;
    transition: all .3s ease-in;
    color: #000063;
    background: transparent;

    &:hover,
    &:active,
    &:focus {
        background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
        color: #ffffff;
    }
}

.cancel-btn {
    background: #D65900 !important;
    border-radius: 10px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #ffffff !important;
    border: 1px solid #D65900 !important;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: #D65900 !important;
        box-shadow: 0 0.5em 0.5em -0.4em rgba(214, 89, 0, 0.5) !important;
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }
}

a {
    &:hover {
        color: var(--primary-color-1);
    }
}

.errorBorder {
    border: 1.5px solid #C10808 !important;
}

.errorTextRed {
    color: #FF6666;
}

.card-black {
    background: #FFFFFF;
    box-shadow: 0px 3px 8px #C1BDBD;
    border-radius: 16px;
    transition: all 1s linear;
}

.card-pool{
    border-radius: 26px;
    background-image: url(../images/team/bg.png);
    background-position: center;
    background-size: cover;
    height: 210px;
}


.card-pool-red{
    border-radius: 26px;
    background-image: url(../images/team/redBg.png);
    background-position: center;
    background-size: cover;
    height: 210px;
}


.card-document {
    background: #F8F8F8;
    border-radius: 16px;
}

.row {
    &.display-flex1 {
        display: flex;
        flex-wrap: wrap;

        [class*='display-flex-col1'] {
            flex-grow: 1;
        }
    }
}

.modal-content {
    background: #16171C;
    backdrop-filter: blur(11.5px);
    border-radius: 30px;

    .title-border-bottom {
        background: #50C3C5;
        border-radius: 10px;
        height: 4px;
        width: 5vw;
    }
}

.table {
    thead {
        tr {
            th {
                border-width: 2px;
                border-color: #ffffff !important;
                background-color: #000063;
            }
        }
    }

    tbody {
        tr {
            td {
                border-width: 2px;
                border-color: #ffffff !important;
                background-color: #F8F8F8;
                text-align: center;
            }
        }
    }
}
.transparent-bg{
    thead {
        tr {
            th {
                border-width: 1px;
                border-color: transparent !important;
                background-color: #FFFFFF !important;
                border-bottom-color: #B8C0C0 !important;
            }
        }
    }

    tbody {
        tr {
            td {
                border-right: none;
                border-left: none;
                border-width: 1px;
                border-color: #B8C0C0 !important;
                background-color: #FFFFFF !important;
                text-align: center;
            }
        }
    }
}

.pagination {
    justify-content: center;
    align-items: center;
}

.pagination a {
    padding: 10px;
    border-right: none;
    border-left: none;
}

.pagination__link--active {
    background: linear-gradient(212.37deg, #AF0C15 -10.02%, #000063 103.79%);
    box-shadow: 0px 4px 15px #1A237B;
    border-radius: 4px;
    padding: 5px;
}

.previous,
.next {
    width: 3rem;
    text-align: center;
}

.status {
    color: #000063;

    &.completed {
        color: #29ABE2;
    }

    &.failed {
        color: #DF000C;
    }

    &.active {
        color: #00B3FF;
    }

    &.pending {
        color: #7000FF;
    }
}

.title-gradient{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb, .dataTables_scrollBody::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
    background: #000063;
}

.jsgrid-grid-body::-webkit-scrollbar, .dataTables_scrollBody::-webkit-scrollbar, .table-responsive::-webkit-scrollbar {
    background-color: transparent;
}

.overflow-wrap-anywhere{
    overflow-wrap:anywhere;
}