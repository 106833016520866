.team-card-name{
    display: block;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #DDB590;
}
.team-card-name-white{
    display: block;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #EBEBEB;

}
.team-card-value{
    display: block;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #EBEBEB;
}

.category-header{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #DDB590;
}

.bullet-img{
    width: 100%;
}

.category-amount{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    text-transform: uppercase;
    color: #EBEBEB;
}

.categoryName{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #EBEBEB;
}

.referral-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.referral-link-box{
    background: linear-gradient(180deg, rgba(249, 249, 249, 0.15) 0%, rgba(249, 249, 249, 0.0975) 100%);
    box-shadow: 0px 4px 16px rgba(24, 55, 51, 0.5);
    border-radius: 15px;
}

.referral-link{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.small-icon{
    width: 30%;
}

.myRankBg{
    background-color: transparent !important;
    background: linear-gradient(85.85deg, rgba(250, 250, 250, 0) 3.38%, rgba(40, 174, 176, 0.66) 99.28%);
    backdrop-filter: blur(21px);
    border-radius: 16px;
    border: 0.5px solid #fff;
}

.pipBonusBg{
    background-color: transparent !important;
    background: linear-gradient(87.97deg, rgba(250, 250, 250, 0) 0.88%, rgba(49, 149, 211, 0.66) 98.89%);
    backdrop-filter: blur(21px);
    border-radius: 16px;
}

.generationBg{
    background-color: transparent !important;
    background: linear-gradient(86.87deg, rgba(255, 255, 255, 0) 1.86%, rgba(255, 203, 154, 0.66) 94.58%);
    backdrop-filter: blur(21px);
    border-radius: 16px;
}

.team-profile-img{
    width: 50%;
    margin-top: -10%;
}

.arrow-img{
    width: 60%;
}
.node-content{
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(21px);
    border-radius: 12px;
}
.node-child-number{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #EBEBEB;
}

.node-child-number{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #EBEBEB;
}

.node-child-title{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #EBEBEB;
}
@media only screen and (min-width: 668px) and (max-width: 992px) {
    .arrow-img{
        width: 10%;
    }
    .team-profile-img{
        width: 30%;
    }
}

@media (min-width: 575px) and (max-width: 667px) {
    .arrow-img{
        width: 10%;
    }

    .team-profile-img{
        width: 30%;
    }

}

@media (min-width: 301px) and (max-width: 574px) {
    .arrow-img{
        width: 10%;
    }
    .team-profile-img{
        width: 20%;
    }
}


@media (max-width: 300px) {
    .arrow-img{
        width: 15%;
    }
}



.level-dot{
    background: linear-gradient(90deg, #AF0C15 -0.1%, #000063 99.9%);
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    @media screen and (max-width:767px) {
        width: 5vw;
        height: 5vw;
    }
}

.referral-card{
    background-image:  url('../images/team/referralBg.png');
    background-size: 100% 100%;
    box-shadow: 0px 3px 8px #C1BDBD;
    border-radius: 16px;

    
}

.input-group-referral{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(24, 33, 55, 0.5);
    border-radius: 56px;
    display: flex;
    width: 100%;
    align-items: center;
}

.referral-qr-container{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(235, 235, 235, 0.7) 100%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
}

.teamicon{
    width:110px;
    height:110px !important;

    @media screen and (max-width:767px) {
        width: 60px;
        height:60px !important;
    }
}

.teamTitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 107%;
    /* or 19px */
    
    /* 000063 */
    color: #000063;
    @media screen and (max-width:767px) {
        font-size: 14px;
    }
}