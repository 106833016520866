.profile-img{
    border-radius: 50%;
    border: 0.5px solid #DDB590;
    overflow: hidden;
    img{
        width: 50%;
        border-radius: 50%;
    }

    @media only screen and (max-width: 47.9375rem){
        .nav-link{
            margin-left: 0px;
            padding-left: 0px;
        }
        img{
            width: 20px;
            height: 20px;
        }
    }
}
.header-right{  
    .dropdown-menu{
        background-color: transparent;
    }
}

.bgGlass{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(21px);
    border-radius: 16px;
    .flag>img{
        width: 100%;
    }
}

.bgNotification{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(21px);
    border-radius: 16px;
}

.timeline-panel{
    cursor: pointer;
}

.fundWalletBtn{
    background: #012060;
    box-shadow: 0px 4px 15px rgba(37, 198, 198, 0.35);
    border-radius: 107px;
    transition: all 1s;
    &:hover{
        background: rgba(1,32,96,0.7)
    }
}

.fundWalletBtnText{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
}

.language-bar{
    background: #ffffff;
    border-radius: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang-text{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #363848;
    // @media only screen and (max-width: 47.9375rem){
    //     display: none;
    // }
}

.lang-chevron-down{
    height: 15px;
    margin-left: 5px;
    @media only screen and (max-width: 47.9375rem){
        display: none;
    }
}

@media screen and (max-width: 480px) {
    [data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
        width: 70%;
    }
}

.pulse-css{
    background: #DC5050 !important;
    border: 0.5px solid #fff !important;
}

.header-right .header-profile .nav-link{
    border-left: none !important;
}

.notification_dropdown{
    .nav-link{
        .svg-inline--fa.fa-bell{
            height: 20px;
            filter: drop-shadow(0px 4px 6px rgba(27, 148, 149, 0.8))
        }
    }
}

.header-right .notification_dropdown .nav-link{
    background: none !important;
    .arrow path{
        fill: #012060 !important;
    }
    .bell path{
        fill: #ffffff !important;
    }
}


.header{
    height: 6.5rem !important;
    @media screen and (max-width:767px) {
        height:auto;
    }
}